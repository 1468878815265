@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
  }
  html{
    overflow-y: hidden;
  }
  body {
    background-color: #dbfee6;
    color: #342e2e;
  }

  .btn {
    box-shadow: inset 0 -1px #342e2e;
    transition: all 0.5s;
  }
  
  .btn-active:active {
    background-color: #dbfee6;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes rotateArrow {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .spin {
    animation: spin 1s linear infinite;
  }
  
  .rotate {
    animation: rotateArrow 0.5s linear;
  }
  
  @layer base {
    * {
        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #342e2e;
            border-radius: 10px;
            border: transparent;
        }

        &::-webkit-scrollbar-corner {
            background: transparent;
        }
    }
}